import http from '../common/http';

export function getHomeProduct() { // 获取首页产品列表
    let url = `/api/DataApi/ProductByAll`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getHomeNews() { // 获取首页新闻列表
    let url = `/api/DataApi/NewsByAll`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getHomeCase() { // 获取首页案例列表
    let url = `/api/Case/pageIndex=1`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getHomePartner() { // 获取首页合作伙伴
    let url = `/api/About/Partner`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getHomeSolution() { // 获取首页解决方案
    let url = `/api/Solution/GetArticleAll`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getHomeContact() { // 获取首页公司信息
    let url = `/api/About/Contact`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getCompanyInfo() { // 获取公司信息
    let url = `/api/About`;
    return http.get(url).then((data) => {
        return data;
    });
}
