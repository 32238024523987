import http from '../common/http';

export function getProductType() { // 获取产品类型列表
    let url = `/api/Product`;
    return http.get(url).then((data) => {
        return data;
    });
}

export function getProductList(param) { // 获取产品列表
    let url = `api/Product/GetProduct/ChannelId=${param.ChannelId}&&pageIndex=${param.pageIndex}&&pageSize=${param.pageSize}`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getProductDetail(ProId) { // 获取产品详情
    let url = `/api/Product/GetProductDetail/ProId=${ProId}`;
    return http.get(url).then((data) => {
        return data;
    });
}
