import axios from 'axios';
import store from '../store'

// axios 全局参数设置
axios.defaults.baseURL = modeUrlObj.baseURL;
axios.defaults.headers.post['Content-Type'] = 'x-www-form-urlencoded';

axios.interceptors.response.use((response) => {
    if (response.data.IsSuccess === true) {
      return response;
    } else if (response.data.Code == "400" || response.data.Code == "401" || response.data.Code == "403") {
      return Promise.reject(response)
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error.response);
  }
);
/**
 * 返回值说明：
 *
 *  code : 0[成功]，其他失败
 *  msg ：请求成功/请求失败
 *  json : response中的data属性
 */

export default {
  /**
   * 封装post请求
   * @param url
   * @param data
   * @returns {Promise}
   */

  post: function (url, data = {}) {
    return new Promise((resolve, reject) => {
      axios.post(url, data).then(response => {
          resolve(response.data);
        },
        err => {
          reject(err)
        }).catch(err => {
        reject(err)
      })
    })
  },
  get: function (url, data = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, data).then(response => {
          resolve(response.data);
        },
        err => {
          reject(err)
        }).catch(err => {
        reject(err)
      })
    })
  },
};


