
export default new Vuex.Store({
  state: {
    productList: [],
    solutionList: [],
    caseList: []
  },
  mutations: {
    set_product_list(state, key) {
      this.state.productList = key;
    },
    set_solution_list(state, key) {
      this.state.solutionList = key;
    },
    set_case_list(state, key) {
      this.state.caseList = key;
    },
  },
  actions: {
  },
  modules: {
  }
})
