// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import './element-variables.scss'
import VueLazyload from 'vue-lazyload'
import animated from 'animate.css' // npm install animate.css --save安装，在引入



import {
  Menu,
  Submenu,
  MenuItem,
  Carousel,
  CarouselItem,
} from 'element-ui';
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Carousel)
Vue.use(CarouselItem)

Vue.use(VueLazyload)

Vue.use(animated)
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  console.log(to)
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // /* 路由发生变化修改页面title */
  if (to.query.name) {
    document.title = to.query.name + '-' + metaData.PageTitle;
  } else if (to.meta.title) {
    document.title = to.meta.title + '-' + metaData.PageTitle;
  }
  next()
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')