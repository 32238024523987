<template>
  <div id="app">
    <div class="ignore-header-box flex flex-pack-center flex-align-center">
      <img :src="logoPic" class="ignore-logo-pic" />
      <div class="flex flex-nowrap z0">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          @mouseenter="item.mouse = true"
          @mouseleave="item.mouse = false"
        >
          <router-link :to="{ name: item.path }">
            <div class="flex flex-align-center ignore-tab-item pointer">
              <div
                class="ignore-active-bg"
                :class="
                  activeTab == item.id
                    ? 'active-bg-show'
                    : item.mouse == true
                    ? 'active-bg-show'
                    : item.mouse == false
                    ? 'active-bg-none'
                    : 'active-bg-no'
                "
              />

              <div
                class="ignore-fz18"
                :class="activeTab == item.id ? 'c-39e' : ''"
              >
                {{ item.name }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="ignore-content-box-home">
      <keep-alive>
        <router-view :key="$route.fullPath" />
      </keep-alive>
    </div>
    <div class="footer-box flex flex-pack-center">
      <div class="footer-product flex-v">
        <div class="fz24 c-d7d mb20">产品中心</div>
        <div
          v-for="(item, index) in productList"
          :key="index"
          class="c-999 mt10 pointer"
        >
          <router-link
            :to="{ name: 'productCenter', query: { typeId: item.channelId, name: item.channelName } }"
            target="_blank"
            >{{ item.channelName }}</router-link
          >
        </div>
      </div>
      <div class="footer-solution flex-v">
        <div class="fz24 c-d7d mb20">解决方案</div>
        <div
          v-for="(item, index) in solutionList"
          :key="index"
          class="c-999 mt10 pointer"
        >
          <router-link
            :to="{ name: 'solution', query: { solId: item.channelId, name: item.articleTitle } }"
            target="_blank"
            >{{ item.articleTitle }}</router-link
          >
        </div>
      </div>
      <div class="footer-case flex-v">
        <div class="fz24 c-d7d mb20">项目案例</div>
        <div
          v-for="(item, index) in caseList"
          :key="index"
          class="c-999 mt10 pointer"
        >
          <router-link
            :to="{ name: 'caseCenter', query: { caseId: item.articleId, name: item.articleTitle } }"
            target="_blank"
            >{{ item.articleTitle }}</router-link
          >
        </div>
      </div>
      <div class="footer-code flex-v flex-align-center">
        <div class="code-box flex-v flex-align-center flex-pack-center">
          <img class="code-pic" v-lazy="codePic" />
          <div class="fz16 c-white">扫一扫 关注我们</div>
        </div>
        <div class="mt10 c-999">Copyright © {{nowYear}} www.sjrt.net</div>
        <a class="c-999 mt5" href="https://beian.miit.gov.cn" target="_blank"
          >ICP备案号：蜀ICP备20005726号-1</a
        >
        <div class="c-999 mt5">版权所有：成都世纪锐通科技有限公司</div>
      </div>
    </div>
  </div>
</template>
<script>
import logoPic from "@/assets/common/logo.png";
import codePic from "@/assets/home/code.jpg";
import { getHomeProduct, getHomeCase, getHomeSolution } from "@/api/home";
import { getProductType } from "@/api/product";
import { getSolutionList } from "@/api/solution";

export default {
  name: "app",
  data() {
    return {
      logoPic,
      codePic,
      activeIndex: "1",
      tabList: [
        {
          name: "首页",
          path: "Home",
          id: 1,
          mouse: -1,
        },
        {
          name: "产品中心",
          path: "productCenter",
          id: 2,
          mouse: -1,
        },
        {
          name: "解决方案",
          path: "solution",
          id: 3,
          mouse: -1,
        },
        {
          name: "项目案例",
          path: "caseCenter",
          id: 4,
          mouse: -1,
        },
        {
          name: "最新动态",
          path: "newsCenter",
          id: 5,
          mouse: -1,
        },
        {
          name: "关于我们",
          path: "about",
          id: 6,
          mouse: -1,
        },
      ],
      activeTab: 1,
      productList: [],
      caseList: [],
      solutionList: [],
      nowYear: ''
    };
  },
  methods: {
    changeTab(item) {
      this.activeTab = item.id;
      this.$router.push(item.path);
    },
    initData() {
      // 获取产品列表
      getProductType().then((res) => {
        let list = res;
        let proList = [];
        list.forEach((item) => {
          let proItem = {
            channelId: item.channelId,
            channelName: item.channelName,
          };
          proList.push(proItem);
        });
        this.productList = proList;
      });
      // 获取案例列表
      getHomeCase().then((res) => {
        let list = res.objList;
        let caseList = [];
        list.forEach((item) => {
          let caseItem = {
            articleId: item.articleId,
            articleTitle: item.articleTitle,
          };
          caseList.push(caseItem);
        });
        this.caseList = caseList;
      });
      // 获取合作伙伴列表
      getSolutionList().then((res) => {
        let solutionList = [];
        res.forEach((item) => {
          let solutionItem = {
            channelId: item.channelId,
            articleTitle: item.channelNameRemark,
          };
          solutionList.push(solutionItem);
        });
        this.solutionList = solutionList;
      });
    },
  },
  mounted() {
    this.initData();
    this.nowYear = (new Date()).getFullYear();
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.path == "/productCenter") {
          this.activeTab = 2;
        } else if (val.path == "/solution") {
          this.activeTab = 3;
        } else if (val.path == "/caseCenter") {
          this.activeTab = 4;
        } else if (val.path == "/newsCenter") {
          this.activeTab = 5;
        } else if (val.path == "/about") {
          this.activeTab = 6;
        } else {
          this.activeTab = 1;
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: MicrosoftYaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  // height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  overflow-x: hidden;
  /*三角箭头的颜色*/
  scrollbar-arrow-color: transparent;
  /*滚动条滑块按钮的颜色*/
  scrollbar-face-color: transparent;
  /*滚动条整体颜色*/
  scrollbar-highlight-color: transparent;
  /*滚动条阴影*/
  scrollbar-shadow-color: transparent;
  /*滚动条轨道颜色*/
  scrollbar-track-color: transparent;
  /*滚动条3d亮色阴影边框的外观颜色——左边和上边的阴影色*/
  scrollbar-3dlight-color: transparent;
  /*滚动条3d暗色阴影边框的外观颜色——右边和下边的阴影色*/
  scrollbar-darkshadow-color: transparent;
  /*滚动条基准颜色*/
  scrollbar-base-color: transparent;
  scrollbar-width: none;
}
body {
  margin: 0 !important;
  font-family: Microsoft YaHei;
}
div {
  box-sizing: border-box;
  font-size: 14px;
  color: #3a3a3a;
}
a {
  text-decoration: none;
}
.ignore-logo-pic {
  height: 44px;
  // margin-left: 42px;
  margin-right: 42px;
}
.el-menu--horizontal {
  border-bottom: none !important;
  .el-menu-item {
    height: 70px !important;
    line-height: 70px !important;
    color: #3a3a3a !important;
  }
  .is-active {
    color: #39e274 !important;
  }
}
.ignore-header-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 14px 40px 0px rgba(0, 0, 0, 0.06);
  .ignore-tab-item {
    height: 70px;
    padding-left: 26px;
    padding-right: 26px;
    position: relative;
  }

  .ignore-active-bg {
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 100%;
    margin: 0 auto; /*水平居中*/
    left: 0; /*此处不能省略，且为0*/
    right: 0; /*此处不能省略，且为0*/
    background: #39e274;
  }

  .active-bg-show {
    width: 100%;
    animation: tabShow 0.7s;
  }
  .active-bg-none {
    width: 0px;
    animation: tabNone 0.7s;
  }
  .active-bg-no {
    width: 0px;
  }
}
.ignore-content-box-home {
  margin-top: 70px;
  width: 100%;
  min-height: 900px;
}
.footer-box {
  width: 100%;
  background: #222;
  padding-top: 64px;
  padding-bottom: 39px;
  a {
    color: #999999;
  }
  .footer-product {
    min-height: 249px;
    border-right: 1px solid #323232;
    min-width: 210px;
  }
  .footer-solution {
    min-height: 249px;
    border-right: 1px solid #323232;
    min-width: 325px;
    padding-left: 86px;
  }
  .footer-case {
    min-height: 249px;
    border-right: 1px solid #323232;
    min-width: 470px;
    padding-left: 90px;
  }
  .footer-code {
    min-height: 249px;
    padding-left: 95px;
    .code-box {
      width: 162px;
      height: 197px;
      background: #282828;
      border: 1px solid #3e3e3e;
      .code-pic {
        height: 120px;
        width: 120px;
        margin-bottom: 16px;
      }
    }
  }
}

.flex {
  display: box; /* OLD - Android 4.4- */
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */

  /* 09版 */
  -webkit-box-orient: horizontal;
  /* 12版 */
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}

.flex-inline {
  display: inline-flex;
}

.flex-v {
  display: box; /* OLD - Android 4.4- */

  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */

  /* 09版 */
  -webkit-box-orient: vertical;
  /* 12版 */
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
  -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1; /* OLD - Firefox 19- */
  -webkit-flex: 1; /* Chrome */
  -ms-flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-0 {
  flex-shrink: 0;
}

.flex-align-start {
  align-items: flex-start;
}

/*垂直居中*/
.flex-align-center {
  /* 09版 */
  -webkit-box-align: center;
  /* 12版 */
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}

/*垂直居中*/
.flex-align-end {
  align-items: flex-end;
}

/*垂直居中*/
.flex-pack-start {
  justify-content: flex-start;
}

/*左对齐*/
.flex-pack-center {
  /* 09版 */
  -webkit-box-pack: center;
  /* 12版 */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
}

/*左对齐*/
.flex-pack-between {
  justify-content: space-between;
}

/*两端对齐*/
.flex-pack-around {
  justify-content: space-around;
}

/*两端对齐*/
.flex-pack-end {
  justify-content: flex-end;
}

/*右对齐*/
.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fz70 {
  font-size: 70px;
}
.fz50 {
  font-size: 50px;
}
.fz40 {
  font-size: 40px;
}
.fz36 {
  font-size: 36px;
}
.fz32 {
  font-size: 32px;
}
.fz24 {
  font-size: 24px;
}
.fz22 {
  font-size: 22px;
}
.fz20 {
  font-size: 20px;
}
.fz18 {
  font-size: 18px;
}
.fz16 {
  font-size: 16px;
}
.fz12 {
  font-size: 12px;
}
.w100 {
  width: 100%;
}
.mw100 {
  max-width: 100%;
}
.h100 {
  height: 100%;
}
.bold {
  font-weight: bold;
}
.bolder {
  font-weight: bolder;
}
.c-cac {
  color: #cacaca;
}
.c-666 {
  color: #666666;
}
.c-999 {
  color: #999999;
}
.c-white {
  color: #fff;
}
.c-39e {
  color: #39e274;
}
.c-399 {
  color: #399b65;
}
.c-ddd {
  color: #ddd;
}
.c-d7d {
  color: #d7d7d7;
}
.c-5b5 {
  color: #5b5b5b;
}
.mt5 {
  margin-top: 5px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt24 {
  margin-top: 24px;
}
.mt30 {
  margin-top: 30px;
}
.mt35 {
  margin-top: 35px;
}
.mt42 {
  margin-top: 42px;
}
.mt62 {
  margin-top: 62px;
}
.mt120 {
  margin-top: 120px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb37 {
  margin-bottom: 37px;
}
.mr10 {
  margin-right: 10px;
}
.mr30 {
  margin-right: 30px;
}
.pb30 {
  padding-bottom: 30px;
}
.pl20 {
  padding-left: 20px;
}
.pr20 {
  padding-right: 20px;
}
.ignore-fz80 {
  font-size: 80px;
}
.ignore-fz48 {
  font-size: 48px;
}
.ignore-fz32 {
  font-size: 32px;
}
.ignore-fz28 {
  font-size: 28px;
}
.ignore-fz24 {
  font-size: 24px;
}
.ignore-fz20 {
  font-size: 20px;
}
.ignore-fz18 {
  font-size: 18px;
}
.ignore-fz16 {
  font-size: 16px;
}
.ignore-fz14 {
  font-size: 14px;
}
.ignore-fz12 {
  font-size: 12px;
}
.ignore-mt8 {
  margin-top: 8px;
}
.ignore-mt10 {
  margin-top: 10px;
}
.ignore-mt14 {
  margin-top: 14px;
}
.ignore-mt20 {
  margin-top: 20px;
}
.ignore-mt26 {
  margin-top: 26px;
}
.ignore-mt30 {
  margin-top: 30px;
}
.ignore-mt40 {
  margin-top: 40px;
}
.ignore-mt50 {
  margin-top: 50px;
}
.ignore-mt60 {
  margin-top: 60px;
}
.ignore-mt80 {
  margin-top: 80px;
}
.ignore-mt120 {
  margin-top: 120px;
}
.ignore-mb20 {
  margin-bottom: 20px;
}
.ignore-mb10 {
  margin-bottom: 10px;
}
.ignore-mb120 {
  margin-bottom: 120px;
}
.ignore-mr16 {
  margin-right: 16px;
}
.ignore-mr20 {
  margin-right: 20px;
}
.uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.pre {
  white-space: pre;
}
.lh30 {
  line-height: 30px;
}
.lh50 {
  line-height: 50px;
}
.ovh {
  overflow: hidden;
}
.z0 {
  z-index: 0;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.max-line1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.max-line2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.max-line3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}
.max-line4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.max-line5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.max-line6 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
}
.ml2-fz22 {
  max-height: 56px;
}
.ml2-fz18 {
  max-height: 48px;
}
.ml2-fz14 {
  max-height: 40px;
  line-height: 1.5em;
}
.ml2-fz12 {
  max-height: 34px;
  line-height: 1.5em;
}
.ignore-ml1-fz12 {
  max-height: 20px;
  line-height: 1.5em;
}
.ignore-ml2-fz12 {
  max-height: 34px;
  line-height: 1.5em;
}
.ignore-ml2-fz14 {
  max-height: 42px;
  line-height: 1.5em;
}
.ignore-ml3-fz14 {
  max-height: 62px;
  line-height: 1.5em;
}
.ignore-ml3-fz12 {
  max-height: 56px;
  line-height: 1.5em;
}
.ignore-ml5-fz14 {
  max-height: 102px;
  line-height: 1.5em;
}
.ova {
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
.color-line {
  width: 50px;
  height: 3px;
  background: linear-gradient(-90deg, #34b5ff, #39e274);
  border-radius: 2px;
}
@keyframes tabShow {
  0% {
    width: 5px;
  }
  100% {
    width: 100%;
  }
}
@keyframes tabNone {
  0% {
    width: 100%;
  }
  100% {
    width: 0px;
  }
}
</style>
<style scoped>
@media (max-width: 1366px) {
  .ignore-header-box {
    height: 50px !important;
  }
  .ignore-logo-pic {
    height: 30px !important;
  }
  .ignore-tab-item {
    height: 50px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .ignore-active-bg {
    height: 2px !important;
  }
  .ignore-content-box-home {
    margin-top: 50px !important;
  }
}
</style>
<style>
@media (max-width: 1366px) {
  .ignore-ml5-fz14 {
    max-height: 92px;
  }
  .ml2-fz12 {
    max-height: 48px;
  }
  .ml2-fz14 {
    max-height: 50px;
  }
}
</style>