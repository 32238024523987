const Home = () => import('@/views/Home.vue')
const ProductCenter = () => import('@/views/productCenter.vue')
const CaseCenter = () => import('@/views/caseCenter.vue')
const NewsCenter = () => import('@/views/newsCenter.vue')
const Solution = () => import('@/views/solution.vue')
const About = () => import('@/views/about.vue')
// let metaData = require('@@@/metaData.json');
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
      content:{
        keywords: metaData.PageKeywords,
        description: metaData.PageDescription,
      },
    }
  },
  {
    path: '/productCenter',
    name: 'productCenter',
    component: ProductCenter,
    meta: {
      title: '产品展示',
      content:{
        keywords: metaData.PageKeywords,
        description: metaData.PageDescription,
      },
    }
  },
  {
    path: '/caseCenter',
    name: 'caseCenter',
    component: CaseCenter,
    meta: {
      title: '案例中心',
      content:{
        keywords: metaData.PageKeywords,
        description: metaData.PageDescription,
      },
    }
  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: NewsCenter,
    meta: {
      title: '新闻资讯',
      content:{
        keywords: metaData.PageKeywords,
        description: metaData.PageDescription,
      },
    }
  },
  {
    path: '/solution',
    name: 'solution',
    component: Solution,
    meta: {
      title: '解决方案',
      content:{
        keywords: metaData.PageKeywords,
        description: metaData.PageDescription,
      },
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: '关于我们',
      content:{
        keywords: metaData.PageKeywords,
        description: metaData.PageDescription,
      },
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
