import http from '../common/http';

export function getSolutionList() { // 获取解决方案列表
    let url = `/api/Solution`;
    return http.get(url).then((data) => {
        return data;
    });
}
export function getSolutionDetail(ProId) { // 获取解决方案详情
    let url = `/api/Solution/GetArticle/ChannelId=${ProId}`;
    return http.get(url).then((data) => {
        return data;
    });
}